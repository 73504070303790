module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","siteUrl":"https://master-portfolio.herokuapp.com/","matomoUrl":"https://analytics.kremalicious.com","localScript":"/piwik.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"fernando valle","short_name":"mk","start_url":"/","background_color":"#e7eef4","theme_color":"#88bec8","icon":"src/images/favicon.png","display":"minimal-ui","cache_busting_mode":"name"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
